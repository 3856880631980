import * as msal from '@azure/msal-browser'

import type { IAuthProvider, TokenClaims } from '../auth-service'
import { env } from '../utils'

export class MsalProvider implements IAuthProvider {
  msalInstance: msal.PublicClientApplication
  msalScopes = [env.AUTH_CLIENT_ID, 'openid', 'offline_access']
  msalConfig: msal.Configuration = {
    auth: {
      clientId: env.AUTH_CLIENT_ID,
      authority: env.AUTH_PROVIDER_URI,
      redirectUri: env.AUTH_REDIRECT_URI,
      knownAuthorities: env.AUTH_AUTHORITIES
    }
  }

  userLang = 'pt-br'

  #localId: string | null = null

  get currentAccount(): msal.AccountInfo {
    let account: msal.AccountInfo | null
    // O campo localId vai conter o id da conta que fez login pela última vez
    // Caso o usuário possua várias contas, será utilizada a última acessada
    const localId = this.#localId ?? window.sessionStorage.getItem('currentAccount')
    if (localId !== null) {
      account = this.msalInstance.getAccountByLocalId(localId)
    } else {
      // Fallback para pegar a primeira conta existente no msal
      // Este cenário vai acontecer quando o usuário já possui o registro local
      // e ainda não fez um novo login onde é setado o "currentAccount"
      const accounts = this.msalInstance.getAllAccounts()
      account = accounts.length > 0 ? accounts[0] : null
    }

    if (account === null) {
      throw new Error('User is not logged')
    }

    if (this.#localId === null) {
      // Cache de local id para evitar pegar do session storage na próxima chamada
      this.#localId = account.localAccountId
    }

    return account
  }

  set currentAccount(account: msal.AccountInfo) {
    this.#localId = account.localAccountId
    window.sessionStorage.setItem('currentAccount', account.localAccountId)
  }

  get authResultClaims(): TokenClaims {
    return this.currentAccount?.idTokenClaims as TokenClaims
  }

  constructor() {
    this.msalInstance = new msal.PublicClientApplication(this.msalConfig)
  }

  async isAuthenticated() {
    const redirectResult = await this.msalInstance.handleRedirectPromise()
    if (redirectResult?.account != null) {
      this.currentAccount = redirectResult.account
    }

    const accounts = this.msalInstance.getAllAccounts()
    return accounts.length > 0
  }

  async redirectToLogin() {
    await this.msalInstance.loginRedirect({
      scopes: this.msalScopes,
      extraQueryParameters: { ui_locales: this.userLang }
    })
  }

  redirectToLogout() {
    return this.msalInstance.logoutRedirect({ extraQueryParameters: { ui_locales: this.userLang } })
  }

  async getTokenAsync() {
    const request = this.#getAcquireTokenRequest()
    const { idToken, idTokenClaims } = await this.msalInstance.acquireTokenSilent(request)
    return { token: idToken, claims: idTokenClaims as TokenClaims }
  }

  getTokenAsyncRedirect() {
    const request = this.#getAcquireTokenRequest()
    return this.msalInstance.acquireTokenRedirect({
      ...request,
      extraQueryParameters: { ui_locales: this.userLang }
    })
  }

  #getAcquireTokenRequest() {
    return {
      scopes: this.msalScopes,
      account: this.currentAccount
    }
  }

  static makeInstance() {
    return new MsalProvider()
  }
}
