import { type UserData } from '../shared/types'

export const makeUserData = (): UserData => ({
  id: 'f4392386-5767-44a9-a007-b063eca3f1b7',
  active: true,
  createdAt: '',
  email: '',
  geraId: '',
  name: 'Usuário Boticário',
  profiles: [],
  updatedAt: ''
})
