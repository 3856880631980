import { type AuthService } from '../../auth-service'
import { listUserFeatures } from '../../shared/api/users'
import { type UserFeatures } from '../../shared/types'
import { noticeError } from '../../utils'
import { type CanAccessProps, type IAuthorization } from './authorization.types'

export class AuthorizationByFeature implements IAuthorization {
  private _features: UserFeatures[] | undefined = []

  constructor(private readonly authService: AuthService) {}

  get features() {
    return this._features
  }

  public async loadFeatures() {
    try {
      this._features = await this.listFeatures()
      return this._features
    } catch (error) {
      noticeError(error as Error)
    }
  }

  public canAccess({ featureName }: CanAccessProps) {
    return Boolean(this.features?.includes(featureName))
  }

  private async listFeatures() {
    const response = await listUserFeatures()

    if (response.ok) {
      const features = response.value
      return features.map((feature) => feature.code)
    }

    if (response.code !== 404) {
      throw response.error
    }

    return []
  }
}
